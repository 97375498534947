












































































































import { Component, Vue } from 'vue-property-decorator'
import Swal from 'sweetalert2'
import BaseButton from '@/components/BaseButton.vue'
import { api } from '../services/api.service'

type Prize = {
  prize_enabled: boolean;
  prize_title_en: string | null;
  prize_title_nl: string | null;
  prize_description_en: string | null;
  prize_description_nl: string | null;
  prize_image: string | null;
  prize_background: string | null;
  prize_terms: string | null;
}

@Component({
  components: {
    BaseButton
  }
})
export default class PrizeView extends Vue {
  form = {
    first_name: '',
    last_name: '',
    gender: 'default',
    email: '',
    newsletter: false,
    terms: false
  }

  async mounted () {
    try {
      await this.$store.dispatch('getNextQuestion')
    } catch (error) {
      // nothing
    }

    if (this.hasBackground) {
      this.$store.commit('setBackgroundVideo', this.background)
    }

    if (this.hasQuizId) {
      const claimed = window.localStorage.getItem(this.prizeCode)

      if (claimed === 'claimed') {
        // return this.$router.replace({ name: 'results' })
      }
    }
  }

  beforeDestroy () {
    this.$store.commit('setBackgroundVideo', '')
  }

  get prize (): Prize {
    return this.$store.state.prize
  }

  get title (): string | null {
    return this.selectedLocale === 'en'
      ? this.prize.prize_title_en
      : this.prize.prize_title_nl
  }

  get hasTitle (): boolean {
    return this.title !== null
  }

  get selectedLocale (): 'en' | 'nl' {
    return this.$root.$i18n.locale as 'en' | 'nl'
  }

  get description (): string | null {
    return this.selectedLocale === 'en'
      ? this.prize.prize_description_en
      : this.prize.prize_description_nl
  }

  get image (): string | null {
    return this.prize.prize_image
  }

  get hasBackground (): boolean {
    return this.background !== null
  }

  get background (): string | null {
    return this.prize.prize_background
  }

  get hasImage (): boolean {
    return this.image !== null
  }

  get terms (): string | null {
    return this.prize.prize_terms
  }

  get hasTerms (): boolean {
    return this.terms !== null
  }

  get quizId (): string | null {
    return this.$store.state.quiz_id
  }

  get hasQuizId () {
    return this.quizId !== null
  }

  get prizeCode (): string {
    return `prize-${this.quizId}`
  }

  async onSubmit () {
    if (this.hasTerms) {
      if (!this.form.terms) {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: this.$t('prize.termswarning') as string
        })

        return
      }
    }

    try {
      await api.request.post('/newsletter', this.form)
    } catch (error) {
      // do nothing
    }

    this.claimPrize()
  }

  claimPrize () {
    if (this.hasQuizId) {
      window.localStorage.setItem(this.prizeCode, 'claimed')
    }

    this.$router.replace({ name: 'results' })
  }
}
